import * as React from 'react';
import Typography from '../components/Typography';
import { LandingLayout } from '../components/layout';
import { Button  } from "../components/input";

const backgroundImage =
    'https://images.unsplash.com/photo-1594729095022-e2f6d2eece9c?q=80&w=2942&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D';

export default function ProductHero() {
    return (
        <LandingLayout
            sxBackground={{
                backgroundImage: `url(${backgroundImage})`,
                backgroundColor: '#7fc7d9', // Average color of the background image.
                backgroundPosition: 'center',
            }}
        >
            {/* Increase the network loading priority of the background image. */}
            <img
                style={{ display: 'none' }}
                src={backgroundImage}
                alt="increase priority"
            />
            <Typography color="inherit" align="center" variant="h2" marked="center">
                Mnemetic Software Solutions
            </Typography>
            <Button
                color="secondary"
                variant="contained"
                size="large"
                component="a"
                href="/contact"
                sx={{ minWidth: 200 }}
            >
                Contact
            </Button>
        </LandingLayout>
    );
}