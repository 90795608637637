import * as React from 'react';
import MnemeticAppBar from "./views/MnemeticAppBar";
import MnemeticLandingContent from "./views/MnemeticLandingContent"
import MnemeticAppFooter from "./views/MnemeticAppFooter";


export default function App() {
    return (
        <React.Fragment>
            <MnemeticAppBar />
            <MnemeticLandingContent />
            <MnemeticAppFooter />
        </React.Fragment>
    );
}
